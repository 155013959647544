import React from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import useFeatureFlagContext from "decentraland-gatsby/dist/context/FeatureFlag/useFeatureFlagContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"

import { OperativeSystem } from "../../components/Landing/LandingDownloadOptions/LandingDownloadOptions.types"
import { LandingFooterV2 } from "../../components/Landing/LandingFooterV2"
import { DownloadSuccessLayout } from "../../components/Layout/DownloadSuccessLayout"
import { DownloadSuccessStepsWithOs } from "../../components/Layout/DownloadSuccessLayout.types"
import Layout from "../../components/Layout/Layout"
import {
  Repo,
  useLatestGithubRelease,
} from "../../hooks/useLatestGithubRelease"
import appleLogo from "../../images/apple-logo.svg"
import MacOsSetup from "../../images/download/creator-hub/mac_setup.svg"
import MacOsAppIcon from "../../images/download/creator-hub/macos_app_icon.svg"
import MacOsDownloadFolder from "../../images/download/creator-hub/macos_downloads_folder.svg"
import WindowsAppIcon from "../../images/download/creator-hub/windows_app_icon.svg"
import WindowsDownloadFolder from "../../images/download/creator-hub/windows_downloads_folder.svg"
import WindowsSetup from "../../images/download/creator-hub/windows_setup.svg"
import microsoftLogo from "../../images/microsoft-logo.svg"

export default function DownloadSuccessPage(props: any) {
  const searchParams = new URLSearchParams(props.location.search)
  const os = (searchParams.get("os") ||
    OperativeSystem.MACOS) as OperativeSystem
  const arch = (
    os === OperativeSystem.WINDOWS
      ? "amd64"
      : searchParams.get("arch") || "arm64"
  ) as "amd64" | "arm64"

  const l = useFormatMessage()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ff, { loaded: featureFlagsLoaded, loading: featureFlagsLoading }] =
    useFeatureFlagContext()

  const { links, loading: isLoadingLinks } = useLatestGithubRelease(
    Repo.CREATOR_HUB
  )

  if (!featureFlagsLoaded || featureFlagsLoading || isLoadingLinks || !links) {
    return <Loader active size="large" />
  }

  const downloadedSuccessSteps: DownloadSuccessStepsWithOs = {
    Windows: [
      {
        title: l("page.creator-hub.download.success.steps.windows.step1.title"),
        text: (
          <>
            {l("page.creator-hub.download.success.steps.windows.step1.text", {
              span: (text: string) => <span>{text}</span>,
            })}
          </>
        ),
        image: WindowsDownloadFolder,
      },
      {
        title: l("page.creator-hub.download.success.steps.windows.step2.title"),
        text: (
          <>
            {l("page.creator-hub.download.success.steps.windows.step2.text", {
              span: (text: string) => <span>{text}</span>,
            })}
          </>
        ),
        image: WindowsSetup,
      },
      {
        title: l("page.creator-hub.download.success.steps.windows.step3.title"),
        text: l("page.creator-hub.download.success.steps.windows.step3.text"),
        image: WindowsAppIcon,
      },
    ],
    macOS: [
      {
        title: l("page.creator-hub.download.success.steps.macos.step1.title"),
        text: (
          <>
            {l("page.creator-hub.download.success.steps.macos.step1.text", {
              span: (text: string) => <span>{text}</span>,
            })}
          </>
        ),
        image: MacOsDownloadFolder,
      },
      {
        title: l("page.creator-hub.download.success.steps.macos.step2.title"),
        text: (
          <>
            {l("page.creator-hub.download.success.steps.macos.step2.text", {
              span: (text: string) => <span>{text}</span>,
            })}
          </>
        ),
        image: MacOsSetup,
      },
      {
        title: l("page.creator-hub.download.success.steps.macos.step3.title"),
        text: (
          <>
            {l("page.creator-hub.download.success.steps.macos.step3.text", {
              span: (text: string) => <span>{text}</span>,
            })}
          </>
        ),
        image: MacOsAppIcon,
      },
    ],
  }

  return (
    <Layout isFullscreen hideFooter={true} className="layout__navbar--fixed">
      <Head
        title={l("page.download.title") || ""}
        description={l("page.download.description") || ""}
        image="https://decentraland.org/images/decentraland.png"
      />

      <DownloadSuccessLayout
        productAction={l("page.download.success.subtitle_action_creating")}
        osIcon={os === OperativeSystem.WINDOWS ? microsoftLogo : appleLogo}
        osLink={links[os][arch]}
        steps={downloadedSuccessSteps[os]}
        footerLinkLabel={l(
          "page.creator-hub.download.success.footer_link_label"
        )}
      />

      <LandingFooterV2 />
    </Layout>
  )
}
